import { ProjectCard } from 'components';
import { ArrowLeftIcon, ArrowRightIcon, ExternalLinkIcon } from 'components/common/icons';
import { Link } from 'gatsby';
import React, { useCallback, useState } from 'react';
import { Project } from 'utils/types';
import { SectionHeading, SectionWrapper, StyledProject } from './styles';

type ProjectGridProps = {
  projects: Project[];
  filters: string[];
}

const ProjectGrid: React.FC<ProjectGridProps> = ({ projects, filters }) => {

  const [currentFilter, setCurrentFilter] = useState('all');

  const [filteredProjects, setFilteredProjects] = useState(projects);

  const handleFilters = useCallback((filter = 'all') => {
    if (filter === currentFilter) return;
    const newFilteredProjects = (filter === 'all') ? projects : projects.filter(project => {
      if (project.skills.some(skill => skill.name === filter)) return project;
      if (project.tags.includes(filter)) return project;
      return null;
    });
    setFilteredProjects(newFilteredProjects);
    setCurrentFilter(filter);
  }, [projects, filters, currentFilter]);

  return (
    <div className="project-grid">

      <SectionWrapper className="section-wrapper">

        <SectionHeading className="section-heading">
          <h5 className="caption">PORTFOLIO</h5>
          <h1 className="title">My Projects:</h1>
          <p className="description">
            I'm Passionate about Interactive Digital Experiences that aim to translate Good Design to Good Business.
          </p>

          <div className="filters">
            {
              filters.map(filter => (
                <button
                  key={filter}
                  className={`filter ${currentFilter === filter ? 'active' : ''}`}
                  onClick={() => handleFilters(filter)}
                >
                  {filter}
                </button>
              ))
            }
          </div>

          <Link to='/' className="btn link-btn">
            <div className="btn-icon-wrapper">
              <ArrowLeftIcon className='btn-icon' />
            </div>
            <div className="btn-text">Back To Home</div>
          </Link>

        </SectionHeading>

        {
          filteredProjects.map((project) => (
            <ProjectCard
              key={project.id}
              styledWrapper={StyledProject}
              data={project}
            />
          ))
        }
      </SectionWrapper>

    </div>

  )
}

export function ProjectCard2({ data }) {

  return (
    <div className='project-card'>

      <div className="image-data" style={{ backgroundColor: data.color }}>
        <img className='' src={data.cover} alt={data.title} />
      </div>

      <div className="content">

        <Link to={`/projects/${data.slug.current}/`}>
          <h2 className="name">{data.title}</h2>
        </Link>

        <p className="description">
          {data.description}
        </p>

        <div className="links">

          <Link to={`/projects/${data.slug.current}/`} className="link-wrapper">
            <ExternalLinkIcon className='link-icon' />
            <div className="link-text">VIEW PROJECT</div>
            <ArrowRightIcon className='link-icon' />
          </Link>

        </div>

      </div>

    </div>
  );

}

export default ProjectGrid;
