import { StyledProjectCardProps } from "components/project/styles";
import styled from "styled-components";

type SectionHeaderProps = {
  backgroundImage: string;
}

export const StyledSection = styled.section`
  background-color: var(--bg-color);
  footer {
    background: linear-gradient(
      180deg,
      var(--bg-color) 40%,
      var(--bg-dark-color) 0
    ) !important;

    .section-wrapper {
      max-width: 60rem !important;
    }

    .contact-section {
      border: 1px solid var(--border-color);
    }
  }

  .project-grid {
    padding: 5rem 2.5rem;
  }

  @media only screen and (max-width: 1024px) {
    footer {
      .contact-section {
        border: 0;
      }
    }
    .project-grid {
      padding: 5rem 5rem 1rem;
    }
  }
  @media only screen and (max-width: 720px) {
    .project-grid {
      padding: 3.5rem 2.5rem;
    }
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  background-image: url(${(props: SectionHeaderProps) => props.backgroundImage});
  background-color: var(--blue);
  padding: 1.5rem 2.5rem;
  .app-logo {
    height: 2.4rem !important;
  }

  @media only screen and (max-width: 720px) {
    padding: 1.25rem;
  }
`;

export const SectionWrapper = styled.div`
  gap: 2rem;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  justify-content: space-between;
  max-width: 60rem !important;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(1,1fr);
    max-width: 30rem !important;
  }
`;

export const SectionHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .filters {
    display: flex;
    flex-wrap: wrap;
    .filter {
      cursor: pointer;
      font-weight: 600;
      font-size: 0.65rem;
      margin-right: .5rem;
      margin-bottom: .5rem;
      padding: .15rem .5rem;
      letter-spacing: 0.02rem;
      text-transform: uppercase;
      background-color: lighten(silver,10);
      &.active { background-color: var(--yellow) }
      &:hover { background-color: var(--yellow) }
    }
  }

  .link-btn {
    padding: 0 !important;
    display: flex;
    height: 2.25rem;
    cursor: pointer;
    border-radius: 0;
    margin-top: 1.5rem;
    align-items: center;
    width: fit-content;
    border: 1px solid var(--blue);
    background-color: var(--white) !important;

    &::before {
      opacity: 1 !important;
      background: var(--blue) !important;
    }

    .btn-icon-wrapper {
      padding: 0;
      height: 100%;
      width: 2.5rem;
      align-items: center;
      justify-content: center;
      background: var(--blue) !important;
      border-radius: 0 !important;
      display: inline-flex;
    }

    .btn-icon {
      height: 1.1rem;
      font-size: 1.1rem;
      color: var(--white) !important;
      width: 1.1rem;
    }

    .btn-text {
      width: auto;
      padding: 0 .5rem;
      font-weight: 500;
      font-size: 0.7rem;
      color: var(--blue) !important;
    }

    &:hover {
      .btn-text {
        color: var(--white) !important;
        &::before { width: 100% !important; }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .description {
      line-height: 1.9;
    }
  }
`;

export const StyledProject = styled.article`
  display: grid;
  cursor: pointer;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  border: 1px solid var(--border-color);
  background-color: var(--white);

  .image-wrapper {
    background-color: ${(props: StyledProjectCardProps) => props.projectColor};
    .cover-image {
      width: 100% !important;
      height: 15rem !important;
    }
  }

  .content {
    padding: 1rem;

    .tags, .project-stack {
      display: none;
    }

    .name {
      margin: 0;
      font-family: var(--bold);
      text-transform: upperCase;
      font-size: 1rem !important;
    }

    .description {
      line-height: 1.8;
      margin:  0.75rem 0;
      font-size: .8rem;
      padding-right: 0.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

  }

  &:hover {
    box-shadow: var(--shadow);
    border-color: ${(props: StyledProjectCardProps) => props.projectColor};
  }

  @media only screen and (max-width: 1024px) {
    .content {
      .description {
        line-height: 1.9;
        font-size: 0.85rem;
      }
    }
  }
`;
