import pattern from 'assets/images/pattern.svg'
import { AppLogo, Head, Main, NavMenu } from 'components'
import { PageProps } from 'gatsby'
import React, { useMemo } from 'react'
import { Project } from 'utils/types'
import Footer from 'views/footer'
import ProjectGrid from './projects'
import { SectionHeader, StyledSection } from './styles'

interface TemplateProps extends PageProps {
  pageContext: { projects: Project[] }
}

const ProjectsTemplate: React.FC<TemplateProps> = ({ pageContext }) => {

  const { projects, filters } = useMemo(() => {
    const data = pageContext.projects.map(project => {
      return { ...project, links: { internal: `/projects/${project.slug}/`} }
    });
    const tags = data.reduce((projectTags, project) => projectTags.concat(project.tags), []);
    const skills = data.reduce((projectSkills, project) => projectSkills.concat(project.skills.map(s => s.name)), []);
    return { projects: data, filters: ['all', ...new Set(tags.concat(skills))] }
  }, [pageContext]);

  return (
    <>
      <Head
        title="Projects"
        tags={filters}
      />
      <Main>
        <NavMenu />
        <StyledSection id='project-catalogue'>
          <SectionHeader backgroundImage={pattern}>
            <AppLogo/>
          </SectionHeader>
          <ProjectGrid filters={filters} projects={projects} />
          <Footer />
        </StyledSection>
      </Main>
    </>
  );
}

export default ProjectsTemplate;
